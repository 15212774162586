import { Controller } from "stimulus";

export default class extends Controller {
  static targets = [
    "cardElement",
    "errors",
    "submitButton",
    "submitText",
    "name",
    "productName",
  ];
  static values = {
    stripeKey: String,
    clientSecret: String,
    productId: String,
  };

  initialize() {
    this.stripe = Stripe(this.stripeKeyValue);
    this.card = null;

    this.stripeElements();
  }

  stripeElements() {
    let elements = this.stripe.elements();

    // Card Element styles
    let style = {
      base: {
        fontSize: "16px",
        color: "#00003c",
        fontFamily:
          "-apple-system, BlinkMacSystemFont, Segoe UI, Roboto, sans-serif",
        fontSmoothing: "antialiased",
        "::placeholder": {
          color: "#a0aec0",
        },
      },
    };

    this.card = elements.create("card", { style: style });

    this.card.mount(this.cardElementTarget);

    let _this = this;
    this.card.on("focus", function () {
      _this.errorsTarget.classList.add("focused");
    });

    this.card.on("blur", function () {
      _this.errorsTarget.classList.remove("focused");
    });

    this.card.on("change", function (event) {
      _this.displayError(event);
    });
  }

  submit(event) {
    event.preventDefault();
    event.stopPropagation();

    this.changeLoadingState(true);
    this.changeFormState(false);

    this.stripe
      .confirmCardSetup(this.clientSecretValue, {
        payment_method: {
          card: this.card,
          billing_details: {
            name: this.nameTarget.value,
          },
        },
      })
      .then((result) => {
        if (result.error) {
          // The card had an error when trying to attach it to a customer
          throw result;
        }

        return result.setupIntent.payment_method;
      })
      .then(this.updatePaymentMethod.bind(this))
      .then(this.onCardConfirmed.bind(this))
      .catch((error) => {
        // An error has happened. Display the failure to the user here.
        // We utilize the HTML element we created.
        this.displayError(error);
      });
  }

  updatePaymentMethod(payment_method_id) {
    return fetch("/billing/payment_method", {
      method: "post",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        payment_method_id,
        product_id: this.productIdValue, // this might be blank if we are just updating payment info (as opposed to subscribing)
      }),
    })
      .then((response) => {
        return response.json();
      })
      .then((result) => {
        if (result.error) {
          throw result;
        }
        return result;
      });
  }

  onCardConfirmed() {
    window.location.href = "/billing";
  }

  displayError(event) {
    this.changeLoadingState(false);
    this.changeFormState(true);

    if (event.error) {
      this.errorsTarget.textContent = event.error.message;
    } else {
      this.errorsTarget.textContent = "";
    }
  }

  changeFormState(isEnabled) {
    this.element.getElementsByTagName("fieldset")[0].disabled = !isEnabled;
  }

  changeLoadingState(isLoading) {
    if (isLoading) {
      this.submitTextTarget.innerText = "Saving ...";
      this.submitButtonTarget.disable = true;
    } else {
      this.submitTextTarget.innerText = "Save";
      this.submitButtonTarget.disable = false;
    }
  }
}
