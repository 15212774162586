import { Controller } from "stimulus";

export default class extends Controller {
  static values = { url: String, type: String };
  static targets = ["domain", "validity"];

  initialize() {
    this.check = this.debounce(this.check, 1000);
  }

  remove() {
    this.element.remove();
  }

  check() {
    let domain = this.domainTarget.value;
    if (this.typeValue === "omgimg") {
      domain += ".omgimg.net";
    }

    this.validityTarget.innerHTML = `<div class="spinner-border spinner-border-sm" role="status">
        <span class="visually-hidden">Loading...</span>
      </div>`;

    fetch(this.urlValue + "?domain=" + encodeURIComponent(domain))
      .then((response) => response.text())
      .then((html) => (this.validityTarget.innerHTML = html));
  }

  debounce(func, delay, immediate) {
    let timerId;
    return (...args) => {
      const boundFunc = func.bind(this, ...args);
      clearTimeout(timerId);
      if (immediate && !timerId) {
        boundFunc();
      }
      const calleeFunc = immediate
        ? () => {
            timerId = null;
          }
        : boundFunc;
      timerId = setTimeout(calleeFunc, delay);
    };
  }
}
