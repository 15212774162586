import Alert from "bootstrap/js/dist/alert";
import Button from "bootstrap/js/dist/button";
// import "bootstrap/js/dist/carousel";
import Collapse from "bootstrap/js/dist/collapse";
import Dropdown from "bootstrap/js/dist/dropdown";
// import "bootstrap/js/dist/modal";
import Popover from "bootstrap/js/dist/popover";
// import "bootstrap/js/dist/scrollspy";
// import "bootstrap/js/dist/tab";
// import "bootstrap/js/dist/toast";
// import Tooltip from "bootstrap/js/dist/tooltip";

export default { Alert, Button, Collapse, Dropdown, Popover };
