import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["domains"];
  static values = { addUrl: String };

  connect() {}

  addOmgImg() {
    fetch(this.addUrlValue + "?domain=" + encodeURIComponent(".omgimg.net"))
      .then((response) => response.text())
      .then((html) => this.domainsTarget.insertAdjacentHTML("beforeend", html));
  }

  addCustom() {
    fetch(this.addUrlValue + "?domain=")
      .then((response) => response.text())
      .then((html) => this.domainsTarget.insertAdjacentHTML("beforeend", html));
  }
}
