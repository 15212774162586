import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["showSecret", "editSecret", "changeSecretKey"];

  editSecret() {
    this.showSecretTarget.classList.add("d-none");
    this.editSecretTarget.classList.remove("d-none");
    this.changeSecretKeyTarget.value = true;
  }

  revertEditSecret() {
    this.editSecretTarget.classList.add("d-none");
    this.showSecretTarget.classList.remove("d-none");
    this.changeSecretKeyTarget.value = false;
  }
}
