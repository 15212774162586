import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["source", "gcs", "aws", "azure"];

  initialize() {
    this.blocks = { gcs: this.gcsTarget, aws: this.awsTarget, azure: this.azureTarget };
  }

  connect() {
    this.init();
  }

  init() {
    const blockElms = [
      ...this.element.getElementsByClassName("settings-block"),
    ];

    blockElms.forEach((block) => {
      block.classList.add("d-none");
    });

    const block = this.blocks[this.sourceTarget.value];

    if (block) {
      block.classList.remove("d-none");
    }
  }

  submit(event) {
    const blockElms = [
      ...this.element.getElementsByClassName("settings-block"),
    ];

    blockElms.forEach((block) => {
      if (
        block.getAttribute("data-settings-target") != this.sourceTarget.value
      ) {
        block.remove();
      }
    });
  }
}
