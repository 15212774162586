// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

import Rails from "@rails/ujs";
import "channels";
import { Turbo } from "@hotwired/turbo-rails";

Turbo.setProgressBarDelay(250);

Rails.start();

import "controllers";

import "@popperjs/core";
import bootstrap from "./bootstrap_custom.js";
import "../stylesheets/application";

import "chartkick";
import "chart.js";

let initPopover = () => {
  var popoverTriggerList = [].slice.call(
    document.querySelectorAll('[data-bs-toggle="popover"]')
  );
  popoverTriggerList.map(function (popoverTriggerEl) {
    return new bootstrap.Popover(popoverTriggerEl, {
      trigger: "focus",
    });
  });
};

addEventListener("turbo:load", function () {
  initPopover();
});

// Useful for sticky elements like the date filter
let scrollTop = 0;

addEventListener("turbo:click", ({ target }) => {
  if (target.hasAttribute("data-turbo-preserve-scroll")) {
    scrollTop = document.scrollingElement.scrollTop;
  }
});

addEventListener("turbo:load", () => {
  if (scrollTop) {
    document.scrollingElement.scrollTo(0, scrollTop);
  }

  scrollTop = 0;
});

addEventListener("turbo:submit-start", function () {
  Turbo.navigator.delegate.adapter.showProgressBar();
});
